import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { AGENCY_TENANT } from '~/core/constants/enum'
import { PAGES_NOT_FETCH_API } from '~/core/constants/url'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'
import { isInCareerHubApp } from '~/core/utilities/common'
import QueryTenantReferralCHubSettingShow from '~/lib/features/career-hub/graphql/query-tenant-referral-chub'
import useDetectCompanyWithKind from '~/lib/hooks/use-detect-company-with-kind'
import QueryTenantReferralSettingShow from '../graphql/query-tenant-referral'
import QueryTenantReferralAgencySettingShow from '../graphql/query-tenant-referral-agency'
import { ReferralSettingType } from '../types'

const useReferralSetting = ({ suspend }: { suspend?: boolean } = {}) => {
  const router = useRouter()
  const { clientGraphQL } = useContextGraphQL()
  const [dataReferral, setDataReferral] = useState<ReferralSettingType>()
  const { isCompanyKind, isLoaded } = useDetectCompanyWithKind({
    kind: AGENCY_TENANT
  })

  const fetchReferralSettingShow = useCallback(() => {
    return clientGraphQL
      .query(
        isInCareerHubApp(router.asPath)
          ? QueryTenantReferralCHubSettingShow
          : isCompanyKind
          ? QueryTenantReferralAgencySettingShow
          : QueryTenantReferralSettingShow
      )
      .then(
        (result: {
          error: { graphQLErrors: Array<object> }
          data: { tenantReferralSettingShow: ReferralSettingType }
        }) => {
          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error,
              error404ShouldForceToNotFoundPage: true
            })
          }

          const { tenantReferralSettingShow } = result.data || []
          setDataReferral(tenantReferralSettingShow)
          return
        }
      )
  }, [clientGraphQL, isCompanyKind])

  useEffect(() => {
    if (!PAGES_NOT_FETCH_API.some((url) => router?.pathname.startsWith(url))) {
      !suspend && isLoaded && fetchReferralSettingShow()
    }
  }, [suspend, isLoaded])

  return { dataReferral, fetchReferralSettingShow }
}
export default useReferralSetting
