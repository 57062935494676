import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import { Controller, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import configuration from '~/configuration'
import { IRouterWithID } from '~/core/@types/global'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { defaultColorBgAvatar } from '~/core/ui/Avatar'
import { Button } from '~/core/ui/Button'
import ComboboxSelect from '~/core/ui/ComboboxSelect'
import { DynamicImportForm } from '~/core/ui/DynamicImportForm'
import { FormControlItem } from '~/core/ui/FormControlItem'
import {
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger
} from '~/core/ui/Popover'
import { ISelectOption } from '~/core/ui/Select'
import { SingleDatePicker } from '~/core/ui/SingleDatePicker'
import { TextAreaWithActions } from '~/core/ui/TextAreaWithActions'
import {
  defaultFormatDate,
  defaultFormatDateTime
} from '~/core/utilities/format-date'
import { adminAndMemberCanAction } from '~/core/utilities/permission'
import useQueryHiringMembersList from '~/lib/features/candidates/hooks/use-query-hiring-members-list'
import QueryAssigneesList from '~/lib/features/tasks/graphql/query-assignees-list'
import schemaTaskForm from '~/lib/features/tasks/schema/task-form'
import {
  AssigneeType,
  FilterAssigneesListType,
  TaskFormInActiveType,
  TaskFormType
} from '~/lib/features/tasks/types'
import useBoundStore from '~/lib/store'
import TaskRelatedSelect from '../Calendar/Tasks/TaskRelatedSelect'
import { useGetTaskDrawerRef } from './TaskUserContainer'

const TaskForm: FC<{
  autoFocus?: boolean
  isEdited: boolean
  isExpand: boolean
  setIsExpand: Dispatch<SetStateAction<boolean>>
  onClose: () => void
  defaultValue?: TaskFormType
  formInActive?: TaskFormInActiveType
  isSubmitting?: boolean
  onFinish: (data: FieldValues) => Promise<void>
  isDeleting?: boolean
  onDelete?: (event: React.MouseEvent<HTMLElement>) => void

  isCallResetAfterSubmitForm?: boolean
  candidateProfileId?: number
  applicantId?: IRouterWithID
  companyId?: number
  isShowEveryoneOption?: boolean
  showSelectTaskRelated?: boolean
  classNameDropdownRelatedTo?: string
}> = ({
  autoFocus,
  isEdited = false,
  isExpand,
  setIsExpand,
  onClose,
  defaultValue,
  isSubmitting,
  onFinish,
  isDeleting,
  onDelete,
  isCallResetAfterSubmitForm = true,
  candidateProfileId,
  formInActive,
  applicantId,
  companyId,
  isShowEveryoneOption = true,
  showSelectTaskRelated,
  classNameDropdownRelatedTo
}) => {
  const { t, i18n } = useTranslation()
  const { clientGraphQL } = useContextGraphQL()
  const setToast = useBoundStore((state) => state.setToast)
  const user = useBoundStore((state) => state.user)
  const wrapperRef = useRef<HTMLDivElement>(null)
  const currentRole = useBoundStore((state) => state.currentRole)

  interface ObjectLiteral {
    [key: string]: any
  }

  let paramQueryHiringMembers: ObjectLiteral = {
    currentUserAsMe: true
  }

  if (applicantId) {
    paramQueryHiringMembers = {
      ...paramQueryHiringMembers,
      applicantId: Number(applicantId)
    }
  } else {
    paramQueryHiringMembers = {
      ...paramQueryHiringMembers,
      profileId: Number(candidateProfileId)
    }
  }

  if (isShowEveryoneOption) {
    paramQueryHiringMembers = {
      ...paramQueryHiringMembers,
      everyoneOption: {
        value: '-1',
        isHideAvatar: true,
        supportingObj: {
          name: `${t(
            'candidates:tabs:candidateNote:hiringMemberSelect:defaultName'
          )}`,
          description: `${t(
            'candidates:tabs:candidateNote:hiringMemberSelect:defaultDescription'
          )}`,
          defaultColour: defaultColorBgAvatar
        }
      }
    }
  }

  const { promiseHiringMemberOptions, optionHiringMemberDefault } =
    useQueryHiringMembersList(paramQueryHiringMembers)

  const promiseAssigneesOptions = (params = {} as FilterAssigneesListType) =>
    clientGraphQL
      .query(QueryAssigneesList, {
        ...params,
        ...(candidateProfileId && !applicantId
          ? { profileId: Number(candidateProfileId) }
          : undefined),
        ...(applicantId ? { applicantId: Number(applicantId) } : undefined)
      })
      .toPromise()
      .then((result: IResponseContextResult<AssigneeType>) => {
        if (result.error) {
          return {
            metadata: {
              totalCount: configuration.defaultAsyncLoadingOptions
            },
            collection: []
          }
        }

        const { assigneesList } = result.data
        const collection = assigneesList?.collection || []
        const metadata = assigneesList?.metadata || {}

        const cloneData = collection.map((item) => {
          return {
            value: item.id,
            avatar: item.avatarVariants?.thumb?.url,
            avatarVariants: item.avatarVariants,
            supportingObj: {
              name: Number(item.id) === Number(user.id) ? 'Me' : item.fullName,
              defaultColour: item.defaultColour
            }
          }
        })

        return { metadata, collection: cloneData }
      })

  const portalContainer = useGetTaskDrawerRef()
  return (
    <div ref={wrapperRef}>
      <DynamicImportForm
        isShowDebug={false}
        className="w-full"
        schema={schemaTaskForm(t)}
        onSubmit={onFinish}
        defaultValue={defaultValue}>
        {({ formState, control, reset, submit, setError, setValue }) => {
          return (
            <Controller
              control={control}
              name="title"
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                useEffect(() => {
                  reset()
                  // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [formInActive?.type])

                return (
                  <TextAreaWithActions
                    autoFocus={autoFocus}
                    variant={isEdited ? 'outline' : 'unstyled'}
                    value={
                      formInActive && formInActive.type === 'add'
                        ? value
                        : formInActive && formInActive.type === 'edit'
                        ? value
                        : ''
                    }
                    onChange={onChange}
                    isExpand={isExpand}
                    setIsExpand={(value) => {
                      setIsExpand(value)
                    }}
                    destructiveText={
                      formState.errors &&
                      (formState.errors?.title?.message as string)
                    }
                    defaultValue={defaultValue?.title}
                    placeholder={`${t('task:enterYourTaskHere')}`}
                    limit={500}
                    maxHeight={isEdited ? 450 : 323}
                    actions={{
                      leftComponents: (
                        <div className="flex items-center">
                          <div className="mr-2">
                            <Controller
                              control={control}
                              name="assigneeIds"
                              defaultValue={[]}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <ComboboxSelect
                                    portalContainer={
                                      portalContainer || wrapperRef.current
                                    }
                                    options={promiseHiringMemberOptions}
                                    size="sm"
                                    isMulti
                                    isClearable={false}
                                    dropdownMenuClassName="!w-[320px]"
                                    containerMenuClassName="max-w-[320px]"
                                    onChange={(newValue) => {
                                      if (
                                        (newValue as ISelectOption[])?.length >
                                        0
                                      ) {
                                        onChange(newValue)
                                      } else {
                                        setToast({
                                          open: true,
                                          type: 'error',
                                          title: t(
                                            'notification:pleaseSelectAtLeastAssignee',
                                            { number: 1 }
                                          ),
                                          classNameConfig: {
                                            viewport: 'mb-[48px]'
                                          }
                                        })
                                      }
                                    }}
                                    configSelectOption={{
                                      supportingText: ['name']
                                    }}
                                    placeholder={`${t(
                                      'label:placeholder:assignTo'
                                    )}`}
                                    searchPlaceholder={`${t(
                                      'label:placeholder:assignTo'
                                    )}`}
                                    loadingMessage={`${t('label:loading')}`}
                                    noOptionsMessage={`${t('label:noOptions')}`}
                                    value={value}
                                    destructive={
                                      formState.errors &&
                                      !!formState.errors.assigneeIds
                                    }
                                  />
                                )
                              }}
                            />
                          </div>
                          <div>
                            <Controller
                              control={control}
                              name="dueDate"
                              defaultValue={undefined}
                              render={({ field: { onChange, value } }) => {
                                const [openDueDate, setOpenDueDate] =
                                  // eslint-disable-next-line react-hooks/rules-of-hooks
                                  useState<boolean>(false)
                                return (
                                  <FormControlItem
                                    destructive={
                                      formState.errors &&
                                      !!formState.errors?.dueDate
                                    }
                                    destructiveText={
                                      formState.errors &&
                                      (formState.errors?.dueDate
                                        ?.message as string)
                                    }>
                                    <Popover
                                      open={openDueDate}
                                      onOpenChange={(open) =>
                                        setOpenDueDate(open)
                                      }>
                                      <PopoverTrigger asChild>
                                        <div>
                                          <Button
                                            type="secondary"
                                            size="xs"
                                            icon="trailing"
                                            label={
                                              value
                                                ? defaultFormatDateTime(value)
                                                : `${t('label:dueDate')}`
                                            }
                                            iconMenus="ChevronDown"
                                            className={
                                              openDueDate
                                                ? 'ring-2 ring-primary-300 ring-offset-2'
                                                : ''
                                            }
                                          />
                                        </div>
                                      </PopoverTrigger>

                                      <PopoverPortal
                                        container={
                                          portalContainer || wrapperRef.current
                                        }>
                                        <PopoverContent
                                          side="top"
                                          align="start"
                                          className="w-full min-w-[300px]">
                                          <SingleDatePicker
                                            locale={i18n.language}
                                            useOnlyFunction
                                            className="max-w-[107px]"
                                            config={{
                                              defaultOpen: true,
                                              disabled: { before: new Date() },
                                              onChange,
                                              value,
                                              showSelectTime: true
                                            }}
                                            placeholder={`${t(
                                              'label:placeholder:dueDate'
                                            )}`}
                                            size="sm"
                                          />
                                        </PopoverContent>
                                      </PopoverPortal>
                                    </Popover>
                                  </FormControlItem>
                                )
                              }}
                            />
                          </div>
                          {showSelectTaskRelated &&
                            adminAndMemberCanAction(currentRole?.code) && (
                              <div className="mx-2">
                                <Controller
                                  control={control}
                                  name="relatedIds"
                                  defaultValue={undefined}
                                  render={({ field: { onChange, value } }) => {
                                    return (
                                      <TaskRelatedSelect
                                        value={value}
                                        onChange={onChange}
                                        formState={formState}
                                        buttonSize="xs"
                                        classNameDropdown={
                                          classNameDropdownRelatedTo
                                        }
                                        maxLength={8}
                                      />
                                    )
                                  }}
                                />
                              </div>
                            )}
                        </div>
                      ),
                      onCancelText: `${t('button:cancel')}`,
                      onCancel: () => {
                        reset()
                        onClose()
                      },
                      onSubmitText: isEdited
                        ? `${t('button:update')}`
                        : `${t('button:create')}`,
                      onSubmit: (value) => {
                        if (value === '') {
                          setIsExpand(true)
                          setValue('title', '')
                          setError('title', {
                            type: 'custom',
                            message: `${t('form:requiredField')}`
                          })
                        } else {
                          submit &&
                            submit().then(() => {
                              isCallResetAfterSubmitForm &&
                                !formState.errors?.title?.message &&
                                reset()
                            })
                        }
                      }
                    }}
                  />
                )
              }}
            />
          )
        }}
      </DynamicImportForm>
    </div>
  )
}

export default TaskForm
