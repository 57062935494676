import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { CloseButton } from '~/core/ui/CloseButton'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import { ScrollArea } from '~/core/ui/ScrollArea'
import { SuggestionChips } from '~/core/ui/SuggestionChips'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { VIEW_MODE } from '~/lib/features/candidates/utilities/enum'

const AISuggestsSkills: FC<{
  skillsOptions: Array<{ label: string }>
  skillsValue: Array<string>
  onClick: (skills: Array<string>) => void
  profileOverviewRef?: React.RefObject<HTMLDivElement>
  loading?: boolean
  containerRef: React.RefObject<HTMLDivElement>
  aiSuggestSkillsRef: React.RefObject<HTMLDivElement>
  onClose?: () => void
  profileViewMode: string
}> = ({
  skillsOptions,
  onClick,
  profileOverviewRef,
  loading,
  skillsValue,
  containerRef,
  aiSuggestSkillsRef,
  onClose,
  profileViewMode
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    const updatePopoverPosition = () => {
      const rPosition = profileViewMode === VIEW_MODE.page ? 0 : 61 // padding left on drawer
      if (containerRef.current && aiSuggestSkillsRef.current) {
        const skillsContainer = containerRef.current.getBoundingClientRect()
        aiSuggestSkillsRef.current.style.transform = `translate(${
          skillsContainer.right - rPosition
        }px, ${containerRef?.current?.getBoundingClientRect().top}px)`
      }
    }
    if (aiSuggestSkillsRef.current) {
      profileOverviewRef?.current?.addEventListener(
        'scroll',
        updatePopoverPosition
      )
    } else {
      profileOverviewRef?.current?.removeEventListener(
        'scroll',
        updatePopoverPosition
      )
    }
    updatePopoverPosition()
    return () => {
      profileOverviewRef?.current?.removeEventListener(
        'scroll',
        updatePopoverPosition
      )
    }
  }, [profileOverviewRef, aiSuggestSkillsRef])

  return (
    <div
      ref={aiSuggestSkillsRef}
      className={cn(
        'fixed left-0 top-0 z-50 ml-2 max-h-[400px] w-80 w-[480px] rounded-lg border border-gray-200 bg-white pt-3 shadow-lg'
      )}>
      <div className="h-[54px]">
        <div className="flex items-center justify-between px-4">
          <h4 className="text-lg font-semibold text-gray-900">
            {t('common:aiSuggests')}
          </h4>
          <CloseButton
            iconClassName="w-4 h-4 flex items-start"
            onClick={onClose}
          />
        </div>
        <p className="mt-0.5 px-4 text-sm text-gray-600">
          {t('common:aiSuggestsDescription')}
        </p>
      </div>
      <If
        condition={!loading}
        fallback={
          <div className="my-3 flex items-center justify-center text-sm">
            <IconWrapper
              className="animate-spin text-gray-500 dark:text-gray-400"
              name="Loader2"
              size={16}
            />
          </div>
        }>
        <ScrollArea viewportClassName="h-full w-full rounded-[inherit] pb-3 [&>*:first-child]:!block max-h-[332px] flex-1 overflow-y-auto px-4">
          <div className="mt-3 flex flex-wrap gap-2">
            <If
              condition={skillsOptions.length > 0}
              fallback={
                <TypographyText className="text-sm text-gray-600">
                  {t('common:noSkillSuggests')}
                </TypographyText>
              }>
              <SuggestionChips
                size="sm"
                source={skillsOptions?.map((item: { label: string }) => ({
                  label: item.label,
                  onClick: () => {
                    const newSkills = [...(skillsValue || []), item.label]
                    onClick(newSkills)
                  }
                }))}
                type="default"
                startIconMenus="Plus"
                classNameChip="rounded-xl bg-gray-100 border-gray-100 hover:bg-gray-300"
                startIconMenusClassName="text-gray-500"
                classNameChipText="text-gray-600 font-medium text-sm rounded-none bg-transparent"
              />
            </If>
          </div>
        </ScrollArea>
      </If>
    </div>
  )
}

export default AISuggestsSkills
