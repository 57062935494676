import { FC, useEffect, useRef, useState } from 'react'
import {
  Control,
  Controller,
  FieldPath,
  FormState,
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
  UseFormTrigger,
  useWatch
} from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import useEnumsData from 'src/hooks/data/use-enums-data'
import CustomField, { CustomFieldComponentType } from '~/components/CustomField'
import HTMLDisplay from '~/components/HTMLDisplay'
import useSubscriptionPlan from '~/components/Subscription/useSubscriptionPlan'
import configuration from '~/configuration'
import { IRouterWithID, ISelectOption } from '~/core/@types/global'
import { ROLE } from '~/core/constants/role'
import { Avatar } from '~/core/ui/Avatar'
import { LockIcon } from '~/core/ui/FillIcons'
import { FormControlItem } from '~/core/ui/FormControlItem'
import IconWrapper from '~/core/ui/IconWrapper'
import If from '~/core/ui/If'
import {
  InlineEditingEditor,
  InlineEditingInput,
  InlineEditingInputGroup
} from '~/core/ui/InlineEditing'
import {
  InlineEditingNoActionsAsyncMultipleSearchWithSelect,
  InlineEditingNoActionsAsyncSingleSearchWithSelect,
  InlineEditingNoActionsDateOrYearPicker,
  InlineEditingNoActionsNativeSelect
} from '~/core/ui/InlineEditingNoActions'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { formatDatePickerToDate } from '~/core/ui/SingleDateWithYearOnlyPicker'
import { SuggestionChips } from '~/core/ui/SuggestionChips'
import { TypographyText } from '~/core/ui/Text'
import { Toggle } from '~/core/ui/Toggle'
import { Tooltip } from '~/core/ui/Tooltip'
import { difference } from '~/core/utilities/common'
import { defaultFormatDate } from '~/core/utilities/format-date'
import {
  adminAndMemberCanAction,
  limitedMemberCanAction
} from '~/core/utilities/permission'
import useCandidateProfile from '~/lib/features/candidates/hooks/use-query-candidate'
import {
  CandidateProfileInputType,
  ICandidateProfile,
  TalentPoolType
} from '~/lib/features/candidates/types'
import {
  getYearOld,
  permittedFieldsManagement,
  useConvertSalary
} from '~/lib/features/candidates/utilities'
import {
  ListSuggestNoticeOfPeriod,
  totalYoeOptions,
  VIEW_MODE
} from '~/lib/features/candidates/utilities/enum'
import usePermissionJob from '~/lib/features/permissions/hooks/use-permission-job'
import usePromiseOwnerOptionsFetchAll from '~/lib/features/settings/members/hooks/use-promise-owner-fetch-all'
import { PLAN_FEATURE_KEYS } from '~/lib/features/settings/plans/utilities/enum'
import useCustomFieldSettingByUser from '~/lib/features/settings/profile-fields/hooks/use-custom-field-setting-by-user'
import { CustomFieldFormType } from '~/lib/features/settings/profile-fields/types/custom-field'
import {
  ADMIN_RECRUITING_RECRUITER_VALUE,
  EVERYONE_VALUE
} from '~/lib/features/settings/profile-fields/utilities/enum'
import useBoundStore from '~/lib/store'
import AISuggestsSkills from '../AISuggestsSkills'
import DisableControlByPermission from '../DisableControlByPermission'
import LanguageView from './Language/LanguageView'

const ProfileInformationView: FC<{
  applicantId?: IRouterWithID
  data: ICandidateProfile
  isLoadingUpdateProfile?: boolean
  onUpdateProfile: (
    data: CandidateProfileInputType & { paramType: string }
  ) => Promise<boolean | void>
  control: Control<any, any>
  trigger: UseFormTrigger<any>
  submit?: () => Promise<void>
  setError?: UseFormSetError<CandidateProfileInputType>
  clearErrors?: UseFormClearErrors<CandidateProfileInputType>
  submitPartialField: (
    fieldName: FieldPath<CandidateProfileInputType>,
    validate: () => Promise<boolean>,
    submit?: () => Promise<void>
  ) => Promise<boolean>
  formState: FormState<CandidateProfileInputType>
  setValue: UseFormSetValue<CandidateProfileInputType>
  configHide?: {
    [key: string]: boolean
  }
  inlineEditingOverlayEdited?: {
    [key: string]: boolean
  }
  disableSourceReferral?: boolean
  profileOverviewRef?: React.RefObject<HTMLDivElement>
  isDrawer?: boolean
}> = ({
  applicantId,
  isLoadingUpdateProfile,
  data,
  onUpdateProfile,
  control,
  trigger,
  submit,
  submitPartialField,
  formState,
  setValue,
  setError,
  clearErrors,
  configHide = {},
  inlineEditingOverlayEdited = {},
  disableSourceReferral = false,
  profileOverviewRef,
  isDrawer
}) => {
  const { t, i18n } = useTranslation()
  const sourced = useWatch({
    control,
    name: 'sourced'
  })
  const {
    promiseSkillsOptions,
    promiseTalentPoolOptions,
    profileSourced,
    currencySalary,
    profileLevel,
    promiseProfileSourceListOptions
  } = useCandidateProfile({ sourced })
  const {
    isFeaturePremiumShow,
    isFeatureEnabled,
    isUnLockFeature,
    showIconFeatureFromAdminSetting: onCheckFunc
  } = useSubscriptionPlan()
  const { numberWithCommas } = useConvertSalary()
  const { currentRole, user } = useBoundStore()
  const { canAccessTalentPool } = usePermissionJob()
  const [isShowSourceName, setIsShowSourceName] = useState(false)
  const permittedFields = data?.permittedFields
  const setEditingCurrentSalaryRef = useRef<(open: boolean) => void>()
  const setEditingExpectedSalaryRef = useRef<(open: boolean) => void>()
  const skillsRef = useRef<HTMLDivElement | null>(null)
  const aiSuggestSkillsRef = useRef<HTMLDivElement | null>(null)
  const [skillsList, setSkillsList] = useState<(string | undefined)[] | null>()
  const [openAISuggestSkills, setOpenAISuggestSkills] = useState<boolean>(false)
  const [isGettingSuggestSkills, setIsGettingSuggestSkills] =
    useState<boolean>(false)
  const [aiSuggestsSkills, setAISuggestsSkills] = useState<
    Array<{ label: string }>
  >([])
  const previousSkillsListRef = useRef<(string | undefined)[] | null>(null) // Lưu giá trị trước đó. Cho phép null để xử lý lần đầu load
  const ProfileTypeOfExpectedSalaryEnums = useEnumsData({
    enumType: 'ProfileTypeOfExpectedSalary',
    locale: i18n.language
  })

  const ProfileTypeOfCurrentSalaryEnums = useEnumsData({
    enumType: 'ProfileTypeOfCurrentSalary',
    locale: i18n.language
  })

  const isShowEquivalentsSkills =
    isFeatureEnabled(PLAN_FEATURE_KEYS.skill_management) &&
    isUnLockFeature(PLAN_FEATURE_KEYS.skill_management)

  const formatTalentPools = (
    data?.permittedFields?.talentPools?.value || []
  )?.map((item: TalentPoolType) => {
    return {
      value: String(item.id),
      supportingObj: {
        name: item.name
      }
    }
  })
  const suggestionChipTalentPools =
    (data?.permittedFields?.talentPools?.value || [])?.map(
      (item: TalentPoolType, index: number) => {
        return {
          id: item.id,
          label: item.name,
          classNameChip: 'prevent-clickable',
          maxLength: 25,
          onClick: () => {
            if (index !== undefined && index !== 9999) {
              window.open(
                `${configuration.path.talentPool.detail(Number(item?.id))}`,
                '_blank'
              )
            }
          }
        }
      }
    ) || []

  const promiseMembersOwnerOptionsFetchAll = usePromiseOwnerOptionsFetchAll()
  const SelectCurrentCurrency = () => {
    return (
      <div>
        <Controller
          control={control}
          name="currentSalaryCurrency"
          render={({ field: { onChange, value } }) => {
            const findSalary = currencySalary.find(
              (item: ISelectOption) =>
                item.value === (value || user?.currentTenant?.currency)
            )
            const formatCurrentSalaryCurrency = findSalary
              ? {
                  value: value || user?.currentTenant?.currency,
                  supportingObj: {
                    name: findSalary?.supportingObj?.name || ''
                  }
                }
              : undefined

            return (
              <FormControlItem>
                <NativeSelect
                  size="sm"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(newValue) => {
                    onChange((newValue as ISelectOption).value)
                  }}
                  value={formatCurrentSalaryCurrency}
                  options={currencySalary}
                  classNameOverride={{
                    bordered: 'none',
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                  menuPosition="fixed"
                />
              </FormControlItem>
            )
          }}
        />
      </div>
    )
  }

  const setEditingCurrentSalaryFunc = (func: any) => {
    setEditingCurrentSalaryRef.current = func
  }

  const setEditingExceptedSalaryFunc = (func: any) => {
    setEditingExpectedSalaryRef.current = func
  }

  const SelectExpertCurrency = () => {
    return (
      <div>
        <Controller
          control={control}
          name="expectedSalaryCurrency"
          render={({ field: { onChange, value } }) => {
            const findSalary = currencySalary.find(
              (item: ISelectOption) =>
                item.value === (value || user?.currentTenant?.currency)
            )
            const formatExpectedSalaryCurrency = findSalary
              ? {
                  value: value || user?.currentTenant?.currency,
                  supportingObj: {
                    name: findSalary?.supportingObj?.name || ''
                  }
                }
              : undefined

            return (
              <FormControlItem>
                <NativeSelect
                  size="sm"
                  isClearable={false}
                  isSearchable={false}
                  onChange={(newValue) => {
                    onChange((newValue as ISelectOption).value)
                    return submitPartialField(
                      'expectedSalaryCurrency',
                      trigger,
                      submit
                    )
                  }}
                  value={formatExpectedSalaryCurrency}
                  options={currencySalary}
                  classNameOverride={{
                    bordered: 'none',
                    loadingMessage: `${t('label:loading')}`,
                    noOptionsMessage: `${t('label:noOptions')}`
                  }}
                  menuPosition="fixed"
                />
              </FormControlItem>
            )
          }}
        />
      </div>
    )
  }
  const { data: customFieldViewData } = useCustomFieldSettingByUser({
    objectKind: 'profile'
  })

  const handleClickOutside = async (event: MouseEvent) => {
    if (
      skillsRef.current &&
      !skillsRef.current.contains(event.target as Node)
    ) {
      if (
        skillsList &&
        JSON.stringify(skillsList) !==
          JSON.stringify(previousSkillsListRef.current)
      ) {
        await submitPartialField('skills', trigger, submit)
        previousSkillsListRef.current = skillsList || [] // Cập nhật giá trị trước đó
        setSkillsList(null)
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [skillsList])

  useEffect(() => {
    if (sourced) {
      if (!data.sourcedNameDescription) {
        setValue('sourcedName', '')
      }
      promiseProfileSourceListOptions({ page: 1, limit: 25 }).then((rs) => {
        if (rs.collection.length > 0) {
          setIsShowSourceName(true)
        } else setIsShowSourceName(false)
      })
    }
  }, [sourced])

  useEffect(() => {
    const handleClickOutsideAISuggestsSkill = async (event: MouseEvent) => {
      if (
        aiSuggestSkillsRef.current &&
        !aiSuggestSkillsRef.current.contains(event.target as Node) &&
        skillsRef.current &&
        !skillsRef.current.contains(event.target as Node)
      ) {
        setOpenAISuggestSkills(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutsideAISuggestsSkill)
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideAISuggestsSkill
      )
    }
  }, [aiSuggestSkillsRef])

  useEffect(() => {
    const getSkillsByProfile = async () => {
      setIsGettingSuggestSkills(true)
      const skills = await promiseSkillsOptions({
        search: '',
        page: 1,
        limit: 1000,
        profileId: data?.id ? Number(data.id) : undefined,
        profileHeadline: data?.headline ? data.headline : undefined
      })

      setAISuggestsSkills(
        skills.collection.map((item: any) => ({ label: item.value }))
      )
      setIsGettingSuggestSkills(false)
    }
    if (openAISuggestSkills) {
      getSkillsByProfile()
    }
  }, [openAISuggestSkills, data])

  return (
    <div className="grid grid-cols-[120px_1fr] desktop:grid-cols-[224px_1fr]">
      {!configHide.publicId ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.publicId?.roles) ||
              permittedFields?.publicId
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="ClipboardList"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:publicId'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.publicId?.roles?.includes(ROLE.Everyone) &&
                  !permittedFields?.publicId?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.publicId?.client_user_visibility === false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.publicId?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 flex items-center px-2 py-1.5">
              <TypographyText className="text-sm text-gray-400">
                {permittedFields?.publicId?.value || '-'}
              </TypographyText>
            </div>
          </If>
        </>
      ) : null}
      {!configHide.headline ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.headline?.roles) ||
              permittedFields?.headline
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Newspaper"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:position'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.headline?.roles?.includes(ROLE.Everyone) &&
                  !permittedFields?.headline?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.headline?.client_user_visibility === false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.headline?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="headline"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="px-2 py-1.5">
                            {value ? (
                              <Tooltip content={value} align="start">
                                <div className="line-clamp-1 text-sm text-gray-900">
                                  {value}
                                </div>
                              </Tooltip>
                            ) : (
                              <div className="line-clamp-1 text-sm text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingInput
                          onChange={(newValue) => {
                            onChange(newValue)
                            return submitPartialField(
                              'headline',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={onChange}
                          placeholder={`${t(
                            'candidates:tabs:candidateOverview:profileInformation:add_position'
                          )}`}
                          size="sm"
                          className="-mx-px px-2"
                          value={value}
                          destructiveText={
                            formState?.errors?.headline?.message as string
                          }
                          tooltipActionCancel={{
                            title: `${t('button:cancel')}`
                          }}
                          tooltipActionSave={{
                            title: `${t('button:saveEnter')}`
                          }}
                          tooltipElement={{
                            title: `${
                              (formState.errors?.headline?.message as string) ||
                              ''
                            }`
                          }}>
                          <div className="px-2 py-1.5">
                            {value ? (
                              <Tooltip content={value} align="start">
                                <div className="line-clamp-1 text-sm text-gray-900">
                                  {value}
                                </div>
                              </Tooltip>
                            ) : (
                              <div className="line-clamp-1 text-sm text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:add_position'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingInput>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.openToWork ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.openToWork?.roles) ||
              permittedFields?.openToWork
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Briefcase"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:openToWork'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.openToWork?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.openToWork?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.openToWork?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.openToWork?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 flex w-full px-2 py-1.5">
              <Controller
                control={control}
                name="openToWork"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <Toggle
                            name="openToWork"
                            isChecked={value}
                            isDisabled
                            size="sm"
                          />
                        }>
                        <Toggle
                          name="openToWork"
                          isChecked={value}
                          onCheckedChange={(checked) => {
                            onChange(checked)
                            return submitPartialField(
                              'openToWork',
                              trigger,
                              submit
                            )
                          }}
                          size="sm"
                        />
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.talentPools && canAccessTalentPool ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.talentPools?.roles) ||
              permittedFields?.talentPools
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="FolderSearch"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:talentPools'
                )}
              </TypographyText>
              <If
                condition={
                  permittedFields?.talentPools?.client_user_visibility === false
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('tooltip:client_cannot_view')}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="profileTalentPoolIds"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipTalentPools}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsAsyncMultipleSearchWithSelect
                          isClearable={false}
                          overlayEdited={
                            !!inlineEditingOverlayEdited.talentPools
                          }
                          onChange={(newValue) => {
                            const talentPoolsList =
                              newValue.length > 0
                                ? newValue.map((item) => item?.value)
                                : []
                            onChange(talentPoolsList)
                            return submitPartialField(
                              'profileTalentPoolIds',
                              trigger,
                              submit
                            )
                          }}
                          configSelectOption={{
                            option: 'checkbox',
                            supportingText: ['name']
                          }}
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          onCancelUpdate={onChange}
                          promiseOptions={promiseTalentPoolOptions}
                          placeholder={`${t(
                            'candidates:tabs:candidateOverview:profileInformation:addTalentPool'
                          )}`}
                          size="sm"
                          value={formatTalentPools}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipTalentPools}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:addTalentPool'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsAsyncMultipleSearchWithSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.profileLevel ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.profileLevel?.roles) ||
              permittedFields?.profileLevel
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Zap"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:experienceLevel'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.profileLevel?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.profileLevel?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.profileLevel?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.profileLevel?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="profileLevel"
                render={({ field: { onChange, value } }) => {
                  const findProfileLevel = profileLevel?.find(
                    (item: ISelectOption) =>
                      String(item.value) === String(value)
                  )
                  const formatProfileLevel = findProfileLevel
                    ? {
                        value: value,
                        supportingObj: {
                          name: findProfileLevel?.supportingObj?.name || ''
                        }
                      }
                    : undefined

                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {formatProfileLevel?.supportingObj?.name}
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsNativeSelect
                          onChange={(newValue) => {
                            onChange(newValue?.value || null)
                            return submitPartialField(
                              'profileLevel',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) =>
                            onChange(newValue?.value)
                          }
                          options={profileLevel}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectExperienceLevel'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={formatProfileLevel}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">
                                {formatProfileLevel?.supportingObj?.name}
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectExperienceLevel'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsNativeSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.totalYearsOfExp ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.totalYearsOfExp?.roles
              ) || permittedFields?.totalYearsOfExp
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Boxes"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:totalYoe'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.totalYearsOfExp?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.totalYearsOfExp?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.totalYearsOfExp?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.totalYearsOfExp?.roles?.join(
                            ', '
                          )
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="totalYearsOfExp"
                render={({ field: { onChange, value } }) => {
                  const checked = ['number', 'string'].includes(typeof value)
                  const formatTotalYearOfExp = checked
                    ? {
                        value: String(value),
                        supportingObj: {
                          name:
                            totalYoeOptions
                              .map((item) => ({
                                ...item,
                                supportingObj: {
                                  name: `${t(
                                    `candidates:yoeOptions:${item.value}`
                                  )}`
                                }
                              }))
                              .find(
                                (item: ISelectOption) =>
                                  item.value === String(value)
                              )?.supportingObj?.name || ''
                        }
                      }
                    : undefined

                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {checked ? (
                              <div className="text-gray-900">
                                {
                                  totalYoeOptions
                                    .map((item) => ({
                                      ...item,
                                      supportingObj: {
                                        name: `${t(
                                          `candidates:yoeOptions:${item.value}`
                                        )}`
                                      }
                                    }))
                                    .find(
                                      (item) => item.value === String(value)
                                    )?.supportingObj?.name
                                }
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsNativeSelect
                          onChange={(newValue) => {
                            onChange(
                              newValue?.value !== undefined
                                ? Number(newValue?.value)
                                : null
                            )
                            return submitPartialField(
                              'totalYearsOfExp',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) => {
                            onChange(newValue?.value)
                          }}
                          options={totalYoeOptions.map((item) => ({
                            ...item,
                            supportingObj: {
                              name: `${t(
                                `candidates:yoeOptions:${item.value}`
                              )}`
                            }
                          }))}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectTotalYearOfExp'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={formatTotalYearOfExp}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {checked ? (
                              <div className="text-gray-900">
                                {
                                  totalYoeOptions
                                    .map((item) => ({
                                      ...item,
                                      supportingObj: {
                                        name: `${t(
                                          `candidates:yoeOptions:${item.value}`
                                        )}`
                                      }
                                    }))
                                    .find(
                                      (item) => item.value === String(value)
                                    )?.supportingObj?.name
                                }
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectTotalYearOfExp'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsNativeSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.skills ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.skills?.roles) ||
              permittedFields?.skills
            }>
            <div>
              <div className="flex w-full items-center space-x-2 py-1.5">
                <IconWrapper
                  size={16}
                  name="ListChecks"
                  className="hidden text-gray-600 desktop:block"
                />
                <TypographyText className="text-sm text-gray-700">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:skills'
                  )}
                </TypographyText>
                <If
                  condition={
                    adminAndMemberCanAction(currentRole?.code) &&
                    !permittedFields?.skills?.roles?.includes(ROLE.Everyone) &&
                    !permittedFields?.skills?.client_user_visibility
                  }>
                  <Tooltip
                    classNameConfig={{ content: 'max-w-full' }}
                    content={
                      permittedFields?.skills?.client_user_visibility === false
                        ? t('tooltip:client_cannot_view')
                        : t('candidates:tabs:candidateOverview:visibleTo', {
                            role: permittedFields?.skills?.roles?.join(', ')
                          })
                    }>
                    <LockIcon />
                  </Tooltip>
                </If>
                <If condition={isShowEquivalentsSkills}>
                  <DisableControlByPermission
                    applicantId={applicantId}
                    text={<></>}>
                    <Tooltip
                      content={t('tooltip:equivalentSkills')}
                      mode="icon">
                      <IconWrapper
                        name="HelpCircle"
                        size={16}
                        className="text-gray-400"
                      />
                    </Tooltip>
                  </DisableControlByPermission>
                </If>
              </div>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="skills"
                render={({ field: { onChange, value } }) => {
                  const skillString = JSON.parse(JSON.stringify(value || []))
                  const formatSkills = skillString.map((item: string) => {
                    return {
                      value: item,
                      supportingObj: {
                        name: item
                      }
                    }
                  })
                  const suggestionChipSkill = skillString.map(
                    (item: string) => {
                      return {
                        label: item,
                        maxLength: 30
                      }
                    }
                  )

                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && value.length > 0 ? (
                              <div className="text-gray-900">
                                <SuggestionChips
                                  size="sm"
                                  source={suggestionChipSkill}
                                  type="default"
                                />
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <div ref={skillsRef}>
                          <InlineEditingNoActionsAsyncMultipleSearchWithSelect
                            isClearable={false}
                            autoUpdate
                            overlayEdited={!!inlineEditingOverlayEdited.skills}
                            creatable
                            onChange={async (value) => {
                              const newValue = JSON.parse(
                                JSON.stringify(value)
                              ) as ISelectOption[]
                              const filterValue = newValue.filter(
                                (item: ISelectOption) =>
                                  !item.__isNew__ ||
                                  (item.__isNew__ && item.value.length < 30)
                              )

                              const skillsList =
                                filterValue.length > 0
                                  ? filterValue.map((item) =>
                                      item?.__isNew__
                                        ? item.label
                                        : item.supportingObj?.name
                                    )
                                  : []

                              setSkillsList(skillsList)
                              onChange(skillsList)
                              return true
                            }}
                            destructiveText={
                              formState?.errors?.skills?.message as string
                            }
                            configSelectOption={
                              isShowEquivalentsSkills
                                ? {
                                    option: 'checkbox',
                                    supportingText: ['description']
                                  }
                                : { option: 'checkbox' }
                            }
                            customIndicator={
                              <div
                                onMouseDown={(e) => {
                                  e.stopPropagation()
                                  setOpenAISuggestSkills(true)
                                }}
                                className="mr-2 cursor-pointer">
                                <span className="block text-sm font-medium text-primary-400">
                                  {t('common:aiSuggests')}
                                </span>
                              </div>
                            }
                            preventClickOutSide={openAISuggestSkills}
                            classNameOverride={{
                              loadingMessage: `${t('label:loading')}`,
                              noOptionsMessage: `${t('label:noOptions')}`
                            }}
                            onInputChange={(inputChange) => {
                              if ((inputChange || '').length > 0)
                                if ((inputChange || '').length > 30) {
                                  setError &&
                                    setError('skills', {
                                      type: 'invalid_input_search',
                                      message: `${t(
                                        'form:field_max_number_required',
                                        { number: 30 }
                                      )}`
                                    })
                                } else {
                                  clearErrors && clearErrors()
                                  trigger()
                                }
                            }}
                            isValidNewOption={(inputChange, options) =>
                              (inputChange || '').length <= 30 &&
                              (options || []).length == 0
                            }
                            callbackClearSearchData={() => {
                              clearErrors && clearErrors()
                              trigger()
                            }}
                            onCancelUpdate={onChange}
                            promiseOptions={(params) => {
                              return promiseSkillsOptions({ ...params })
                            }}
                            placeholder={
                              t(
                                'candidates:tabs:candidateOverview:profileInformation:addSkills'
                              ) as string
                            }
                            size="sm"
                            value={formatSkills}>
                            <div className="flex items-center px-2 py-1.5 text-sm">
                              {value && value.length > 0 ? (
                                <div className="text-gray-900">
                                  <SuggestionChips
                                    size="sm"
                                    source={suggestionChipSkill}
                                    type="default"
                                  />
                                </div>
                              ) : (
                                <div className="text-gray-600">
                                  {t(
                                    'candidates:tabs:candidateOverview:profileInformation:addSkills'
                                  )}
                                </div>
                              )}
                            </div>
                          </InlineEditingNoActionsAsyncMultipleSearchWithSelect>
                          {openAISuggestSkills && (
                            <AISuggestsSkills
                              skillsOptions={difference(
                                aiSuggestsSkills,
                                suggestionChipSkill,
                                'label'
                              )}
                              loading={isGettingSuggestSkills}
                              profileOverviewRef={profileOverviewRef}
                              containerRef={skillsRef}
                              aiSuggestSkillsRef={aiSuggestSkillsRef}
                              skillsValue={value}
                              onClick={(newValues) => {
                                setSkillsList(newValues)
                                onChange(newValues)
                              }}
                              onClose={() => setOpenAISuggestSkills(false)}
                              profileViewMode={
                                isDrawer ? VIEW_MODE.drawer : VIEW_MODE.page
                              }
                            />
                          )}
                        </div>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.language ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.languages?.roles) ||
              permittedFields?.languages
            }>
            <div>
              <div className="flex w-full items-center space-x-2 py-1.5">
                <IconWrapper
                  size={16}
                  name="Languages"
                  className="hidden text-gray-600 desktop:block"
                />
                <TypographyText className="text-sm text-gray-700">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:languages'
                  )}
                </TypographyText>
                <If
                  condition={
                    adminAndMemberCanAction(currentRole?.code) &&
                    !permittedFields?.languages?.roles?.includes(
                      ROLE.Everyone
                    ) &&
                    !permittedFields?.languages?.client_user_visibility
                  }>
                  <Tooltip
                    classNameConfig={{ content: 'max-w-full' }}
                    content={
                      permittedFields?.languages?.client_user_visibility ===
                      false
                        ? t('tooltip:client_cannot_view')
                        : t('candidates:tabs:candidateOverview:visibleTo', {
                            role: permittedFields?.languages?.roles?.join(', ')
                          })
                    }>
                    <LockIcon />
                  </Tooltip>
                </If>
              </div>
            </div>

            <div className="ml-2.5 px-2 py-1.5">
              <LanguageView
                applicantId={applicantId}
                onUpdateProfile={onUpdateProfile}
                data={data}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.currentSalary ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.currentSalary?.roles
              ) || permittedFields?.currentSalary
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="DollarSign"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:currentSalary'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.currentSalary?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.currentSalary?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.currentSalary?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.currentSalary?.roles?.join(
                            ', '
                          )
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            {limitedMemberCanAction(currentRole?.code) &&
            (!permittedFields?.currentSalary?.value ||
              Number(permittedFields?.currentSalary?.value) === 0) ? (
              <div className="ml-2.5 flex w-full flex-row items-center space-x-2">
                <div className="flex items-center px-2 py-1.5 text-sm">
                  <div className="text-gray-600">
                    {t('candidates:tabs:candidateOverview:notAvailable')}
                  </div>
                </div>
              </div>
            ) : (
              <div className="ml-2.5 flex w-full flex-row items-center">
                <div className="w-[90px]">
                  <Controller
                    control={control}
                    name="typeOfCurrentSalary"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem>
                          <DisableControlByPermission
                            applicantId={applicantId}
                            text={
                              <div className="flex items-center px-2 py-1.5 text-sm">
                                <div className="text-gray-900">
                                  {
                                    (value
                                      ? ProfileTypeOfCurrentSalaryEnums.filter(
                                          (type: ISelectOption) =>
                                            type.value === value
                                        )[0]
                                      : ProfileTypeOfCurrentSalaryEnums[0]
                                    ).supportingObj.name
                                  }
                                </div>
                              </div>
                            }>
                            <InlineEditingNoActionsNativeSelect
                              isClearable={false}
                              onChange={(newValue) => {
                                onChange(newValue?.value || '')
                                setEditingCurrentSalaryRef?.current &&
                                  setEditingCurrentSalaryRef?.current(true)
                                return submitPartialField(
                                  'typeOfCurrentSalary',
                                  trigger,
                                  submit
                                )
                              }}
                              onCancelUpdate={(newValue) =>
                                onChange(newValue?.value)
                              }
                              options={ProfileTypeOfCurrentSalaryEnums}
                              size="sm"
                              classNameOverride={{
                                container: 'min-w-[90px]',
                                menu: '!min-w-[116px]',
                                control: 'min-w-[90px]',
                                loadingMessage: `${t('label:loading')}`,
                                noOptionsMessage: `${t('label:noOptions')}`
                              }}
                              value={
                                value
                                  ? ProfileTypeOfCurrentSalaryEnums.filter(
                                      (type: ISelectOption) =>
                                        type.value === value
                                    )
                                  : ProfileTypeOfCurrentSalaryEnums[0]
                              }>
                              <div className="flex min-w-[100px] items-center px-2 py-1.5 text-sm">
                                <div className="text-gray-900">
                                  {
                                    (value
                                      ? ProfileTypeOfCurrentSalaryEnums.filter(
                                          (type: ISelectOption) =>
                                            type.value === value
                                        )[0]
                                      : ProfileTypeOfCurrentSalaryEnums[0]
                                    ).supportingObj.name
                                  }
                                </div>
                              </div>
                            </InlineEditingNoActionsNativeSelect>
                          </DisableControlByPermission>
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
                <div className="mx-1 h-0.5 w-0.5 bg-gray-400" />
                <div className="flex-1">
                  <Controller
                    control={control}
                    name="currentSalary"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem>
                          <DisableControlByPermission
                            applicantId={applicantId}
                            text={
                              <div className="flex items-center px-2 py-1.5 text-sm">
                                {value ? (
                                  <div className="text-gray-900">
                                    {numberWithCommas(value)}{' '}
                                    {permittedFields?.currentSalaryCurrency
                                      ?.value || user?.currentTenant?.currency}
                                  </div>
                                ) : (
                                  <div className="text-gray-600">
                                    {t(
                                      'candidates:tabs:candidateOverview:notAvailable'
                                    )}
                                  </div>
                                )}
                              </div>
                            }>
                            <InlineEditingInputGroup
                              setTriggerInputFunc={setEditingCurrentSalaryFunc}
                              onChange={(newValue) => {
                                onChange(Number(newValue))
                                return submitPartialField(
                                  'currentSalary',
                                  trigger,
                                  submit
                                )
                              }}
                              onCancelUpdate={onChange}
                              renderedElement={SelectCurrentCurrency()}
                              placeholder={t(
                                'candidates:tabs:candidateOverview:profileInformation:addCurrentSalary'
                              ).toString()}
                              size="sm"
                              value={value}
                              tooltipActionCancel={{
                                title: `${t('button:cancel')}`
                              }}
                              tooltipActionSave={{
                                title: `${t('button:saveEnter')}`
                              }}
                              destructiveText={
                                formState?.errors?.currentSalary
                                  ?.message as string
                              }>
                              <div className="flex items-center px-2 py-1.5 text-sm">
                                {value ? (
                                  <div className="text-gray-900">
                                    {numberWithCommas(value)}{' '}
                                    {permittedFields?.currentSalaryCurrency
                                      ?.value || user?.currentTenant?.currency}
                                  </div>
                                ) : (
                                  <div className="text-gray-600">
                                    {t(
                                      'candidates:tabs:candidateOverview:profileInformation:addCurrentSalary'
                                    )}
                                  </div>
                                )}
                              </div>
                            </InlineEditingInputGroup>
                          </DisableControlByPermission>
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
              </div>
            )}
          </If>
        </>
      ) : null}
      {!configHide.expectedSalary ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.expectedSalary?.roles
              ) || permittedFields?.expectedSalary
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="DollarSign"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:expectedSalary'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.expectedSalary?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.expectedSalary?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.expectedSalary?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.expectedSalary?.roles?.join(
                            ', '
                          )
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            {limitedMemberCanAction(currentRole?.code) &&
            (!permittedFields?.expectedSalary?.value ||
              Number(permittedFields?.expectedSalary?.value) === 0) ? (
              <div className="ml-2.5 flex w-full flex-row items-center space-x-2">
                <div className="flex items-center px-2 py-1.5 text-sm">
                  <div className="text-gray-600">
                    {t('candidates:tabs:candidateOverview:notAvailable')}
                  </div>
                </div>
              </div>
            ) : (
              <div className="ml-2.5 flex w-full flex-row items-center">
                <div className="w-[90px]">
                  <Controller
                    control={control}
                    name="typeOfExpectedSalary"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem>
                          <DisableControlByPermission
                            applicantId={applicantId}
                            text={
                              <div className="flex items-center px-2 py-1.5 text-sm">
                                <div className="text-gray-900">
                                  {
                                    (value
                                      ? ProfileTypeOfExpectedSalaryEnums.filter(
                                          (type: ISelectOption) =>
                                            type.value === value
                                        )[0]
                                      : ProfileTypeOfExpectedSalaryEnums[0]
                                    ).supportingObj.name
                                  }
                                </div>
                              </div>
                            }>
                            <InlineEditingNoActionsNativeSelect
                              isClearable={false}
                              onChange={(newValue) => {
                                onChange(newValue?.value || '')
                                setEditingExpectedSalaryRef?.current &&
                                  setEditingExpectedSalaryRef?.current(true)
                                return submitPartialField(
                                  'typeOfExpectedSalary',
                                  trigger,
                                  submit
                                )
                              }}
                              onCancelUpdate={(newValue) =>
                                onChange(newValue?.value)
                              }
                              options={ProfileTypeOfExpectedSalaryEnums}
                              size="sm"
                              classNameOverride={{
                                container: 'min-w-[90px]',
                                menu: '!min-w-[116px]',
                                control: 'min-w-[90px]',
                                loadingMessage: `${t('label:loading')}`,
                                noOptionsMessage: `${t('label:noOptions')}`
                              }}
                              value={
                                value
                                  ? ProfileTypeOfExpectedSalaryEnums.filter(
                                      (type: ISelectOption) =>
                                        type.value === value
                                    )
                                  : ProfileTypeOfExpectedSalaryEnums[0]
                              }>
                              <div className="flex min-w-[100px] items-center px-2 py-1.5 text-sm">
                                <div className="text-gray-900">
                                  {
                                    (value
                                      ? ProfileTypeOfExpectedSalaryEnums.filter(
                                          (type: ISelectOption) =>
                                            type.value === value
                                        )[0]
                                      : ProfileTypeOfExpectedSalaryEnums[0]
                                    ).supportingObj.name
                                  }
                                </div>
                              </div>
                            </InlineEditingNoActionsNativeSelect>
                          </DisableControlByPermission>
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
                <div className="mx-1 h-0.5 w-0.5 bg-gray-400" />
                <div className="flex-1">
                  <Controller
                    control={control}
                    name="expectedSalary"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <FormControlItem>
                          <DisableControlByPermission
                            applicantId={applicantId}
                            text={
                              <div className="flex items-center px-2 py-1.5 text-sm">
                                {value ? (
                                  <div className="text-gray-900">
                                    {numberWithCommas(value)}{' '}
                                    {permittedFields?.expectedSalaryCurrency
                                      ?.value || user?.currentTenant?.currency}
                                  </div>
                                ) : (
                                  <div className="text-gray-600">
                                    {t(
                                      'candidates:tabs:candidateOverview:notAvailable'
                                    )}
                                  </div>
                                )}
                              </div>
                            }>
                            <InlineEditingInputGroup
                              setTriggerInputFunc={setEditingExceptedSalaryFunc}
                              onChange={(newValue) => {
                                onChange(Number(newValue))
                                return submitPartialField(
                                  'expectedSalary',
                                  trigger,
                                  submit
                                )
                              }}
                              onCancelUpdate={onChange}
                              renderedElement={SelectExpertCurrency()}
                              placeholder={
                                t(
                                  'candidates:tabs:candidateOverview:profileInformation:addExpectedSalary'
                                ) as string
                              }
                              size="sm"
                              value={value}
                              tooltipActionCancel={{
                                title: `${t('button:cancel')}`
                              }}
                              tooltipActionSave={{
                                title: `${t('button:saveEnter')}`
                              }}
                              destructiveText={
                                formState?.errors?.expectedSalary
                                  ?.message as string
                              }>
                              <div className="flex items-center px-2 py-1.5 text-sm">
                                {value ? (
                                  <div className="text-gray-900">
                                    {numberWithCommas(value)}{' '}
                                    {permittedFields?.expectedSalaryCurrency
                                      ?.value || user?.currentTenant?.currency}
                                  </div>
                                ) : (
                                  <div className="text-gray-600">
                                    {t(
                                      'candidates:tabs:candidateOverview:profileInformation:addExpectedSalary'
                                    )}
                                  </div>
                                )}
                              </div>
                            </InlineEditingInputGroup>
                          </DisableControlByPermission>
                        </FormControlItem>
                      )
                    }}
                  />
                </div>
              </div>
            )}
          </If>
        </>
      ) : null}
      {!configHide.birthday ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.birthday?.roles) ||
              permittedFields?.birthday
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Cake"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:birthday'
                )}
              </TypographyText>
              <If
                condition={
                  permittedFields?.birthday?.client_user_visibility === false
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={t('tooltip:client_cannot_view')}>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>
            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="birthday"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value?.year ? (
                              <div className="text-gray-900">
                                {!!value?.year &&
                                !value.month &&
                                !value.date ? (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:formatSupportBirthday"
                                    values={{
                                      date: value.year,
                                      years_old: getYearOld(
                                        formatDatePickerToDate(value)
                                      )
                                    }}>
                                    <span className="text-gray-600" />
                                  </Trans>
                                ) : (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:formatSupportBirthday"
                                    values={{
                                      date: defaultFormatDate(
                                        formatDatePickerToDate(value)
                                      ),
                                      years_old: getYearOld(
                                        formatDatePickerToDate(value)
                                      )
                                    }}>
                                    <span className="text-gray-600" />
                                  </Trans>
                                )}
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsDateOrYearPicker
                          inlineType="birthday"
                          onChange={(newValue) => {
                            onChange(newValue)
                            return submitPartialField(
                              'birthday',
                              trigger,
                              submit
                            )
                          }}
                          // onCancelUpdate={(newValue) => onChange(newValue)}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectBirthday'
                            ) as string
                          }
                          value={value}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value?.year ? (
                              <div className="text-gray-900">
                                {!!value?.year &&
                                !value.month &&
                                !value.date ? (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:formatSupportBirthday"
                                    values={{
                                      date: value.year,
                                      years_old: getYearOld(
                                        formatDatePickerToDate(value)
                                      )
                                    }}>
                                    <span className="text-gray-600" />
                                  </Trans>
                                ) : (
                                  <Trans
                                    i18nKey="candidates:tabs:candidateOverview:profileInformation:formatSupportBirthday"
                                    values={{
                                      date: defaultFormatDate(
                                        formatDatePickerToDate(value)
                                      ),
                                      years_old: getYearOld(
                                        formatDatePickerToDate(value)
                                      )
                                    }}>
                                    <span className="text-gray-600" />
                                  </Trans>
                                )}
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectBirthday'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsDateOrYearPicker>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.nationality ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.nationality?.roles) ||
              permittedFields?.nationality
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="Flag"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:nationality'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.nationality?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.nationality?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.nationality?.client_user_visibility ===
                    false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.nationality?.roles?.join(', ')
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="nationality"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingInput
                          onChange={(newValue) => {
                            onChange(newValue)
                            return submitPartialField(
                              'nationality',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={onChange}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:inputCountry'
                            ) as string
                          }
                          size="sm"
                          value={value}
                          tooltipActionCancel={{
                            title: `${t('button:cancel')}`
                          }}
                          tooltipActionSave={{
                            title: `${t('button:saveEnter')}`
                          }}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:inputCountry'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingInput>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.willingToRelocate ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.willingToRelocate?.roles
              ) || permittedFields?.willingToRelocate
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="Rotate3d"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:willingToRelocate'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.willingToRelocate?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.willingToRelocate?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.willingToRelocate
                      ?.client_user_visibility === false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.willingToRelocate?.roles?.join(
                            ', '
                          )
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 flex w-full px-2 py-1.5">
              <Controller
                control={control}
                name="willingToRelocate"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <Toggle
                            name="willingToRelocate"
                            isChecked={value}
                            isDisabled
                            size="sm"
                          />
                        }>
                        <Toggle
                          name="willingToRelocate"
                          isChecked={value}
                          onCheckedChange={(checked) => {
                            onChange(checked)
                            return submitPartialField(
                              'willingToRelocate',
                              trigger,
                              submit
                            )
                          }}
                          size="sm"
                        />
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.noticeToPeriodDays ? (
        <>
          <If
            condition={
              permittedFieldsManagement(
                permittedFields?.noticeToPeriodDays?.roles
              ) || permittedFields?.noticeToPeriodDays
            }>
            <div className="flex w-full items-center space-x-2">
              <IconWrapper
                size={16}
                name="CalendarClock"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:noticeOfPeriods'
                )}
              </TypographyText>
              <If
                condition={
                  adminAndMemberCanAction(currentRole?.code) &&
                  !permittedFields?.noticeToPeriodDays?.roles?.includes(
                    ROLE.Everyone
                  ) &&
                  !permittedFields?.noticeToPeriodDays?.client_user_visibility
                }>
                <Tooltip
                  classNameConfig={{ content: 'max-w-full' }}
                  content={
                    permittedFields?.noticeToPeriodDays
                      ?.client_user_visibility === false
                      ? t('tooltip:client_cannot_view')
                      : t('candidates:tabs:candidateOverview:visibleTo', {
                          role: permittedFields?.noticeToPeriodDays?.roles?.join(
                            ', '
                          )
                        })
                  }>
                  <LockIcon />
                </Tooltip>
              </If>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="noticeToPeriodDays"
                render={({ field: { onChange, value } }) => {
                  const formatNoticeOfPeriods = value
                    ? {
                        value: value,
                        supportingObj: {
                          name: value
                        }
                      }
                    : undefined
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsNativeSelect
                          creatable
                          onChange={(newValue) => {
                            onChange(newValue?.value || '')
                            return submitPartialField(
                              'noticeToPeriodDays',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) =>
                            onChange(newValue?.value)
                          }
                          options={ListSuggestNoticeOfPeriod.map((item) => ({
                            value: `${item.value} ${t('label:days')}`,
                            supportingObj: {
                              name: `${item.value} ${t('label:days')}`
                            }
                          }))}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectTimePeriod'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={formatNoticeOfPeriods}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value ? (
                              <div className="text-gray-900">{value}</div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectTimePeriod'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsNativeSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.ownerId && adminAndMemberCanAction(currentRole?.code) ? (
        <>
          <div className="flex w-[224px] items-center space-x-2">
            <IconWrapper
              size={16}
              name="UserCog"
              className="hidden text-gray-600 desktop:block"
            />
            <TypographyText className="text-sm text-gray-700">
              {t('candidates:tabs:candidateOverview:profileInformation:owner')}
            </TypographyText>
          </div>

          <div className="ml-2.5 w-full">
            <Controller
              control={control}
              name="ownerId"
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControlItem>
                    <DisableControlByPermission
                      applicantId={applicantId}
                      text={
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <>
                              <div className="mr-2">
                                <Avatar
                                  color={value?.supportingObj?.defaultColour}
                                  size="xs"
                                  src={value?.avatarVariants?.thumb?.url}
                                  alt={value?.supportingObj?.name}
                                />
                              </div>
                              <div className="text-gray-900">
                                {value?.supportingObj?.name}
                              </div>
                            </>
                          ) : (
                            <div className="text-gray-600">
                              {t(
                                'candidates:tabs:candidateOverview:notAvailable'
                              )}
                            </div>
                          )}
                        </div>
                      }>
                      <InlineEditingNoActionsAsyncSingleSearchWithSelect
                        onChange={(newValue) => {
                          onChange(newValue?.length && newValue[0])
                          return submitPartialField('ownerId', trigger, submit)
                        }}
                        onCancelUpdate={(newValue) => {
                          onChange(newValue?.length && newValue[0])
                        }}
                        promiseOptions={promiseMembersOwnerOptionsFetchAll}
                        placeholder={
                          t(
                            'candidates:tabs:candidateOverview:contactDetails:owner:search'
                          ) as string
                        }
                        size="sm"
                        configSelectOption={{
                          avatar: true,
                          supportingText: ['name', 'description']
                        }}
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                        value={value ? [value] : []}>
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <div className="flex items-center">
                              <div className="mr-2">
                                <Avatar
                                  color={value?.supportingObj?.defaultColour}
                                  size="xs"
                                  src={value?.avatarVariants?.thumb?.url}
                                  alt={value?.supportingObj?.name}
                                />
                              </div>
                              <TypographyText className="text-sm text-gray-900">
                                {value?.supportingObj?.name}
                              </TypographyText>
                            </div>
                          ) : (
                            <div className="text-gray-600">
                              {t(
                                'candidates:tabs:candidateOverview:contactDetails:owner:search'
                              )}
                            </div>
                          )}
                        </div>
                      </InlineEditingNoActionsAsyncSingleSearchWithSelect>
                    </DisableControlByPermission>
                  </FormControlItem>
                )
              }}
            />
          </div>
        </>
      ) : null}
      {!configHide.summary ? (
        <>
          <If
            condition={
              permittedFieldsManagement(permittedFields?.summary?.roles) ||
              permittedFields?.summary
            }>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="Book"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t('candidates:tabs:candidateOverview:summary:heading')}
              </TypographyText>
            </div>

            <div className="ml-2.5 w-full">
              <DisableControlByPermission
                applicantId={applicantId}
                text={
                  <div className="flex items-center px-2 py-1.5 text-xl">
                    {permittedFields?.summary?.value ? (
                      <HTMLDisplay
                        content={permittedFields?.summary?.value}
                        className="max-w-full text-sm text-gray-900"
                      />
                    ) : (
                      <TypographyText className="text-sm text-gray-600">
                        {t('candidates:tabs:candidateOverview:notAvailable')}
                      </TypographyText>
                    )}
                  </div>
                }>
                <InlineEditingEditor
                  autoSave
                  localStorageId={`profile-${data?.id}-summary`}
                  className="min-w-full"
                  limit={100000}
                  showCount={false}
                  onChange={(newValue) => {
                    return onUpdateProfile({
                      summary: newValue as string,
                      paramType: 'summary'
                    }).then(() => true)
                  }}
                  placeholder={`${t(
                    'candidates:tabs:candidateOverview:summary:addSummary'
                  )}`}
                  size="sm"
                  value={permittedFields?.summary?.value}
                  tooltipActionCancel={{
                    title: `${t('button:cancel')}`
                  }}
                  tooltipActionSave={{
                    title: `${t('button:saveEnter')}`
                  }}>
                  <div className="flex items-center px-2 py-1.5 text-xl">
                    {permittedFields?.summary?.value ? (
                      <HTMLDisplay
                        content={permittedFields?.summary?.value}
                        className="max-w-full text-sm text-gray-900"
                      />
                    ) : (
                      <TypographyText className="text-sm text-gray-600">
                        {t(
                          'candidates:tabs:candidateOverview:summary:addSummary'
                        )}
                      </TypographyText>
                    )}
                  </div>
                </InlineEditingEditor>
              </DisableControlByPermission>
            </div>
          </If>
        </>
      ) : null}
      {!configHide.sourced && adminAndMemberCanAction(currentRole?.code) ? (
        <>
          <div className="flex w-[224px] items-center space-x-2">
            <IconWrapper
              size={16}
              name="Search"
              className="hidden text-gray-600 desktop:block"
            />
            <TypographyText className="text-sm text-gray-700">
              {t(
                'candidates:tabs:candidateOverview:profileInformation:sourced'
              )}
            </TypographyText>
          </div>

          <div className="ml-2.5 w-full">
            <Controller
              control={control}
              name="sourced"
              render={({ field: { onChange, value } }) => {
                const formatSourced = value
                  ? {
                      value: value || '',
                      supportingObj: {
                        name: data.sourcedDescription || ''
                      }
                    }
                  : undefined
                return (
                  <FormControlItem>
                    <DisableControlByPermission
                      applicantId={applicantId}
                      disabled={disableSourceReferral}
                      text={
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <div className="text-gray-900">
                              {
                                profileSourced.filter(
                                  (item: ISelectOption) => item.value === value
                                )[0]?.supportingObj?.name
                              }
                            </div>
                          ) : (
                            <div className="text-gray-600">
                              {t(
                                'candidates:tabs:candidateOverview:notAvailable'
                              )}
                            </div>
                          )}
                        </div>
                      }>
                      <InlineEditingNoActionsNativeSelect
                        onChange={(newValue) => {
                          onChange(newValue?.value || '')
                          return submitPartialField('sourced', trigger, submit)
                        }}
                        onCancelUpdate={(newValue) => onChange(newValue?.value)}
                        options={profileSourced}
                        isClearable={false}
                        placeholder={
                          t(
                            'candidates:tabs:candidateOverview:profileInformation:selectSource'
                          ) as string
                        }
                        size="sm"
                        classNameOverride={{
                          loadingMessage: `${t('label:loading')}`,
                          noOptionsMessage: `${t('label:noOptions')}`
                        }}
                        value={formatSourced}>
                        <div className="flex items-center px-2 py-1.5 text-sm">
                          {value ? (
                            <div className="text-gray-900">
                              {
                                profileSourced.filter(
                                  (item: ISelectOption) => item.value === value
                                )[0]?.supportingObj?.name
                              }
                            </div>
                          ) : (
                            <div className="text-gray-600">
                              {t(
                                'candidates:tabs:candidateOverview:profileInformation:selectSource'
                              )}
                            </div>
                          )}
                        </div>
                      </InlineEditingNoActionsNativeSelect>
                    </DisableControlByPermission>
                  </FormControlItem>
                )
              }}
            />
          </div>
        </>
      ) : null}
      {!configHide.sourcedName && adminAndMemberCanAction(currentRole?.code) ? (
        <>
          <If condition={isShowSourceName}>
            <div className="flex w-[224px] items-center space-x-2">
              <IconWrapper
                size={16}
                name="UserPlus"
                className="hidden text-gray-600 desktop:block"
              />
              <TypographyText className="text-sm text-gray-700">
                {t(
                  'candidates:tabs:candidateOverview:profileInformation:sourcedName'
                )}
              </TypographyText>
            </div>

            <div className="ml-2.5 w-full">
              <Controller
                control={control}
                name="sourcedName"
                render={({ field: { onChange, value } }) => {
                  const format = value
                    ? [
                        {
                          value: value,
                          supportingObj: {
                            name: data.sourcedNameDescription || ''
                          }
                        }
                      ]
                    : []
                  return (
                    <FormControlItem>
                      <DisableControlByPermission
                        applicantId={applicantId}
                        disabled={disableSourceReferral}
                        text={
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && data.sourcedNameDescription ? (
                              <div className="text-gray-900">
                                {data.sourcedNameDescription}
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:notAvailable'
                                )}
                              </div>
                            )}
                          </div>
                        }>
                        <InlineEditingNoActionsAsyncSingleSearchWithSelect
                          onChange={(newValue) => {
                            onChange(
                              newValue?.length > 0 ? newValue[0].value : ''
                            )
                            return submitPartialField(
                              'sourcedName',
                              trigger,
                              submit
                            )
                          }}
                          onCancelUpdate={(newValue) =>
                            onChange(
                              newValue?.length > 0 ? newValue[0].value : ''
                            )
                          }
                          promiseOptions={promiseProfileSourceListOptions}
                          placeholder={
                            t(
                              'candidates:tabs:candidateOverview:profileInformation:selectSource'
                            ) as string
                          }
                          size="sm"
                          classNameOverride={{
                            loadingMessage: `${t('label:loading')}`,
                            noOptionsMessage: `${t('label:noOptions')}`
                          }}
                          value={format}>
                          <div className="flex items-center px-2 py-1.5 text-sm">
                            {value && data.sourcedNameDescription ? (
                              <div className="text-gray-900">
                                {data.sourcedNameDescription}
                              </div>
                            ) : (
                              <div className="text-gray-600">
                                {t(
                                  'candidates:tabs:candidateOverview:profileInformation:selectSource'
                                )}
                              </div>
                            )}
                          </div>
                        </InlineEditingNoActionsAsyncSingleSearchWithSelect>
                      </DisableControlByPermission>
                    </FormControlItem>
                  )
                }}
              />
            </div>
          </If>
        </>
      ) : null}
      {!configHide.sourcedBy && adminAndMemberCanAction(currentRole?.code) ? (
        <>
          {data?.user && (
            <>
              <div className="flex w-[224px] items-center space-x-2">
                <IconWrapper
                  size={16}
                  name="UserPlus"
                  className="hidden text-gray-600 desktop:block"
                />
                <TypographyText className="text-sm text-gray-700">
                  {t(
                    'candidates:tabs:candidateOverview:profileInformation:createdBy'
                  )}
                </TypographyText>
              </div>

              <div className="ml-2.5 flex items-center px-2 py-1.5">
                <div className="mr-2">
                  <Avatar
                    color={data?.user?.defaultColour}
                    size="xs"
                    src={data?.user?.avatarVariants?.thumb?.url}
                    alt={data?.user?.fullName}
                  />
                </div>
                <TypographyText className="text-sm text-gray-900">
                  {data?.user?.fullName}
                </TypographyText>
              </div>
            </>
          )}
        </>
      ) : null}
      {!configHide.createdAt && adminAndMemberCanAction(currentRole?.code) ? (
        <>
          {data?.createdAt && (
            <>
              <div className="flex w-[224px] items-center space-x-2">
                <IconWrapper
                  size={16}
                  name="Clock"
                  className="hidden text-gray-600 desktop:block"
                />
                <TypographyText className="text-sm text-gray-700">
                  {t('label:createdAt')}
                </TypographyText>
              </div>
              <div className="ml-2.5 flex items-center px-2 py-1.5">
                <TypographyText className="text-sm text-gray-900">
                  {defaultFormatDate(new Date(data.createdAt))}
                </TypographyText>
              </div>
            </>
          )}
        </>
      ) : null}
      {!configHide.customFields && customFieldViewData.length > 0 && (
        <Controller
          control={control}
          name="customFields"
          defaultValue={{}}
          render={({ field: { onChange, value } }) => {
            const onChangeRoles = (roleIds: Array<number>) => {
              switch (roleIds.toString()) {
                // case EVERYONE_VALUE.toString():
                //   return `${t(
                //     'settings:custom_fields:permissions:everyoneName'
                //   )}`
                // case ADMIN_VALUE.toString():
                //   return `${t('settings:custom_fields:permissions:adminName')}`
                case ADMIN_RECRUITING_RECRUITER_VALUE.toString():
                  return `${t(
                    'settings:custom_fields:permissions:adminMemberName'
                  )}`
                default:
                  return ''
              }
            }

            return (
              <>
                {customFieldViewData.map((customField, customFieldIndex) => (
                  <CustomField
                    key={customFieldIndex}
                    lockContent={
                      customField.roleIds?.length &&
                      customField.roleIds.toString() !==
                        EVERYONE_VALUE.toString()
                        ? `${t('candidates:tabs:candidateOverview:visibleTo', {
                            role: onChangeRoles(customField.roleIds)
                          })}`
                        : ''
                    }
                    type={customField.type as CustomFieldComponentType['type']}
                    display={
                      limitedMemberCanAction(currentRole?.code)
                        ? 'view_horizontal'
                        : 'inline_editing'
                    }
                    isLoadingSubmitButton={isLoadingUpdateProfile}
                    viewDefaultPlaceholder={
                      limitedMemberCanAction(currentRole?.code)
                        ? `${t(
                            'candidates:tabs:candidateOverview:notAvailable'
                          )}`
                        : '-'
                    }
                    name={customField.name}
                    label={customField.label}
                    error={formState.errors.customFields}
                    editingTriggerValidate={() => {
                      return submitPartialField('customFields', trigger, submit)
                    }}
                    value={
                      Object.values((value || {}) as CustomFieldFormType).find(
                        (item) => String(item.id) === String(customField.id)
                      )?.value || ''
                    }
                    onChange={(fieldValue) => {
                      onChange({
                        ...value,
                        [customField.name]: {
                          ...customField,
                          value: fieldValue
                        }
                      })
                    }}
                    callApiOnChange={true}
                    extraProps={{
                      options: customField.selectOptions
                    }}
                    clientUserVisibility={customField.clientUserVisibility}
                    classNameInlineEditting="ml-2.5"
                  />
                ))}
              </>
            )
          }}
        />
      )}
    </div>
  )
}

export default ProfileInformationView
